import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})

export class ListService {
  private _url: string;
  practitiensSubject : Subject<any[]> = new Subject<any[]>();
  annuairesSubject : Subject<any[]> = new Subject<any[]>();
  practiciens = [];
  annuaires = [];

  /**
   * Constructor
   *
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    this._url = environment.url_api;
  }

  search = new BehaviorSubject<any>(null);
  
  getAllData(Data: any):Promise<any>{    
    return new Promise((resolve, reject) => {
      Promise.all([
        this.searchSp(Data).then(response=> {
          this.search.next(response.body.data);
        })
        // this.getCalls(),
        // this.getUserData()
      ]).then(([]) => {
          resolve([]);
      }, reject);
  });
  }
  /**
   * Add Call
   *
   * @param data
   * @returns {Promise<any>}
   */
  getTimesSlote(data: any): Promise<any> {
    /**
     * Params
     */
    let httpParams = new HttpParams();
    // httpParams = httpParams.append('Param_test', data.param_test);
    httpParams = httpParams.append('reference_directory', data.reference);
    httpParams = httpParams.append('reference', data.reference);
    httpParams = httpParams.append('displays', data.display_days);
    httpParams = httpParams.append('date_init', data.date_init);
    httpParams = httpParams.append('type', '');
    httpParams = httpParams.append('default', '');

    return new Promise((resolve, reject) => {
      this._httpClient
        .post(this._url + '/planning/time_slots', httpParams, {
          // headers: headers,
          // params: httpParams,
          responseType: 'json',
          withCredentials: true,
          observe: 'response',
        })
        .subscribe(
          (response: any) => {
            //  console.log(response);
            resolve(response);
          },
          (err) => {
            console.log('Error: ' + err.error);
            console.log('Name: ' + err.name);
            console.log('Message: ' + err.message);
            console.log('Status: ' + err.status);
          },
          reject
        );
    });
  }


  /////////////////////////



  /**
   * Retreive Person Data
   * * @param keyword
   * *@param data: Array
   * * @returns {Promise<any>}
   */
  searchSp(Data: any): Promise<any> {
    /**
     * Params
     */
    var httpParams = new HttpParams();
    httpParams = httpParams.append('keyword', Data.keyword);
    httpParams = httpParams.append('city', Data.city);
    httpParams = httpParams.append('country', Data.country);
    //  httpParams = httpParams.append('Param_Page', Data.page);
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(this._url + '/search/search_sp', httpParams, {
          //headers: headers,
          //params: httpParams,
          responseType: 'json',
          withCredentials: true,
          observe: 'response',
        })
        .subscribe(
          (response: any) => {
           // console.log(response);
            resolve(response);
          },
          (err) => {
            console.log('Error: ' + err.error);
            console.log('Name: ' + err.name);
            console.log('Message: ' + err.message);
            console.log('Status: ' + err.status);
          },
          reject
        );
    });
  }
  /**
   * get Call Histories
   *
   * @param Data: Array
   * @returns {Promise<any>}
   */
  searchResults(Data: any): Promise<any> {
    /**
     * Params
     */
    let httpParams = new HttpParams();
    httpParams = httpParams.append('speciality', Data.speciality);
    httpParams = httpParams.append('city', Data.city);
    httpParams = httpParams.append('country', Data.country);
    httpParams = httpParams.append('page', Data.page);
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(this._url + '/search/search_result', httpParams, {
          //headers: headers,
          //params: httpParams,
          responseType: 'json',
          withCredentials: true,
          observe: 'response',
        })
        .subscribe(
          (response: any) => {
            //console.log(response);
            this.practiciens=response.body.data.praticiens;
            this.emitPracticiens();
            this.annuaires =response.body.data.annuaires;
            this.emitAnnuaires();
            resolve(response);
          },
          (err) => {
            console.log('Error: ' + err.error);
            console.log('Name: ' + err.name);
            console.log('Message: ' + err.message);
            console.log('Status: ' + err.status);
          },
          reject
        );
    });
  }

  emitPracticiens(){
    this.practitiensSubject.next(this.practiciens);
  }
  emitAnnuaires(){
    this.annuairesSubject.next(this.annuaires);
  }
}
