<footer>       
    <div class="flex">
        <!-- logo and small menu-->
        <div class="w-1/3 p-3">
            <div class="pt-3 pb-3">
                <a class="logo flex" routerLink="/">
                    <app-logo></app-logo>
                </a>
            </div>
            <div class="pl-2 mt-3">
                <ul>
                    <li class="list-unstyled">
                        <a class="" routerLink="/about" target="_blank">À propos de nous</a>
                    </li>
                    <li class="list-unstyled">
                        <a class="" routerLink="/jobs" target="_blank">Jobs</a>
                    </li>
                    <li class="list-unstyled">
                        <a class="" routerLink="/privacy-security" target="_blank">Confidentialité et sécurité</a>
                    </li>
                    <li class="list-unstyled">
                        <a class="" routerLink="/partners-benefits" target="_blank">Prestations partenaires</a>
                    </li>
                    <li class="list-unstyled">
                        <a class="" routerLink="/help-center" target="_blank">Centre d'aide</a>
                    </li>
                    <li class="list-unstyled">
                        <a class="" routerLink="/contact" target="_blank">Contactez-nous</a>
                    </li>
                </ul>
            </div>
            <div class="mt-4 mb-4">
                <app-social-icons fxLayout="row" fxLayoutAlign="start center" class="desc"></app-social-icons>
            </div>
            <div class="flex mt-4 w-48">
                <a class="w-1/2" target="_blank" href="https://apps.tictacsante.com/">
                    <img src="https://cdn.tictacsante.com/assets/images/apple.png" class="cursor-pointer" alt="TicTacSanté apple">
                </a>
                <a class="w-1/2 ml-2" target="_blank" href="https://apps.tictacsante.com/">
                    <img src="https://cdn.tictacsante.com/assets/images/android.png" class="cursor-pointer" alt="TicTacSanté Android">
                </a>
            </div>
        </div>
        <!--speciality-->
        <div class="w-2/3 pt-3">
            <div class="grid grid-cols-2 gap-4">
                <div>
                    <h2 class="mt-3 mb-2">Spécialités</h2>
                    <div class="grid grid-cols-2 gap-4 h-[210px] truncate">
                        <div>
                            <ul>
                                <li class="list-unstyled truncate" *ngFor="let sp of sp1; let index">
                                    <a class="cursor-pointer" (click)="redirectToDoctor(sp?.url)" target="_self">{{sp?.libelle}}</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <ul>
                                <li class="list-unstyled truncate" *ngFor="let sp of sp2; let index">
                                    <a class="cursor-pointer" (click)="redirectToDoctor(sp?.url)" target="_self">{{sp?.libelle}}</a>
                                </li>
                                <!-- <li class="list-unstyled">
                                    <a class="" href="/" target="_self">Médecin généraliste</a>
                                </li>
                                <li class="list-unstyled">
                                    <a class="" href="/" target="_self">Dentiste</a>
                                </li>
                                <li class="list-unstyled">
                                    <a class="" href="/" target="_self">Chirurgien-dentiste</a>
                                </li>
                                <li class="list-unstyled">
                                    <a class="" href="/" target="_self">Dermatologue</a>
                                </li>
                                <li class="list-unstyled">
                                    <a class="" href="/" target="_self">Pédiatre</a>
                                </li>
                                <li class="list-unstyled">
                                    <a class="" href="/" target="_self">Psychologue</a>
                                </li>
                                <li class="list-unstyled">
                                    <a class="" href="/" target="_self">Ophtalmologue</a>
                                </li>
                                <li class="list-unstyled">
                                    <a class="" href="/" target="_self">kinésithérapeute</a>
                                </li>
                                <li class="list-unstyled">
                                    <a class="" href="/" target="_self">Sage-femme</a>
                                </li>
                                <li class="list-unstyled">
                                    <a class="" href="/" target="_self">Chirurgien urologue</a>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                  
                </div>
                <div>
                    <h2 class="mt-3 mb-2">Recherches fréquentes</h2>
                    <ul>
                        <li class="list-unstyled" *ngFor="let frequent of frequents">
                            <a class="cursor-pointer" (click)="redirect(frequent?.url)" target="_self">{{frequent?.speciality}} à {{frequent?.location}}</a>
                        </li>
                        <!-- <li class="list-unstyled">
                            <a class="" href="/" target="_self">Chirurgien-dentiste à Rabat</a>
                        </li>
                        <li class="list-unstyled">
                            <a class="" href="/" target="_self">Médecin généraliste à Rabat</a>
                        </li>
                        <li class="list-unstyled">
                            <a class="" href="/" target="_self">Pédiatre à Rabat</a>
                        </li>
                        <li class="list-unstyled">
                            <a class="" href="/" target="_self">Dermatologue à Rabat</a>
                        </li>
                        <li class="list-unstyled">
                            <a class="" href="/" target="_self">Ostéopathe à Rabat</a>
                        </li>
                        <li class="list-unstyled">
                            <a class="" href="/" target="_self">Ophtalmologue à Rabat</a>
                        </li>
                        <li class="list-unstyled">
                            <a class="" href="/" target="_self">kinésithérapeute à Rabat</a>
                        </li>
                        <li class="list-unstyled">
                            <a class="" href="/" target="_self">Podologue à Rabat</a>
                        </li>                         -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!--search practices-->
    <div class="pl-3 w-full text-center mt-4">
        <h2>Recherche de praticiens</h2>
        <ul class="directory search-footer">
            <li><a target="_self" href="{{ url_origin }}/directory/a">A</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/b">B</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/c">C</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/d">D</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/e">E</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/f">F</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/g">G</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/h">H</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/i">I</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/j">J</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/k">K</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/l">L</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/m">M</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/n">N</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/o">O</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/p">P</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/q">Q</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/r">R</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/s">S</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/t">T</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/u">U</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/v">V</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/w">W</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/x">X</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/y">Y</a></li>
            <li><a target="_self" href="{{ url_origin }}/directory/z">Z</a></li>
        </ul>
    </div>
    <!--footer-->
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="copyright footer-ea">
        <p ngClass.xs="mt-1">Copyright © 2015 / {{currentDate | date:'yyyy'}} All Rights Reserved</p>
        <p>Created with <span class="heart-ea">♥</span> <a class="copyright-ea" href="https://www.e-ambition.com" target="_blank">E-AMBITION</a></p>
    </div>
</footer>
