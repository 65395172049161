import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ResultHeroMapService {
  markerSubject: Subject<any> = new Subject<any>();
  annuairesSubject: Subject<any> = new Subject<any>();
  mapAutoZoomSubject: Subject<any> = new Subject<any>();

  public markers: any[] = [];
  private _url: string;

  /**
   * constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    this._url = environment.url_api;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([]).then(([]) => {
        resolve([]);
      }, reject);
    });
  }
  /**
   * Get Markers
   *
   * @returns {Promise<any>}
   */
  displayMarkers(data): Promise<any> {
    var httpParams = new HttpParams();
    httpParams = httpParams.append("Param_pays", data.country);
    httpParams = httpParams.append("Param_ville", data.city);
    httpParams = httpParams.append("Param_URL_Specialite", data.speciality);
    httpParams = httpParams.append("Param_Page", data.page);

    //let headers = new HttpHeaders();
    // headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(this._url + "/public/ResultMap", httpParams, {
          // headers: headers,
          responseType: "json",
          withCredentials: true,
          observe: "response",
        })
        .subscribe(
          (response: any) => {
            if (response.body.code === 1) {
              resolve(response.body.data);
            } else {
              resolve([]);
            }
          });
    });
  }
  emitPractice(practice) {
    this.markerSubject.next(practice);
  }
  emitZoom() {
    this.mapAutoZoomSubject.next("leave");
  }
  emitAnnuaire(annuaire) {
    this.annuairesSubject.next(annuaire);
  }

  /**
   * Get Practice detail
   *
   * @returns {Promise<any>}
   */
  getPracticeDetail(data): Promise<any> {
    var httpParams = new HttpParams().append("ref", data.id);
    let headers = new HttpHeaders();
    headers = headers.set(
      "Content-Type",
      "application/x-www-form-urlencoded; charset=UTF-8"
    );

    return new Promise((resolve, reject) => {
      this._httpClient
        .post(this._url + "/search/hero_profil", httpParams, {
          headers: headers,
          responseType: "json",
          withCredentials: true,
          observe: "response",
        })
        .subscribe((response: any) => {
          // console.log(response);

          if (response.body.code === 1) {
            resolve(response.body.data);
          } else {
            resolve([]);
          }
        });
    });
  }
  /**
   * Get url
   *
   * @returns {Promise<any>}
   */
  getUrl(data): Promise<any> {
    var httpParams = new HttpParams().append("reference", data.id);
    let headers = new HttpHeaders();
    headers = headers.set(
      "Content-Type",
      "application/x-www-form-urlencoded; charset=UTF-8"
    );

    return new Promise((resolve, reject) => {
      this._httpClient
        .post(this._url + "/planning/url_public", httpParams, {
          headers: headers,
          responseType: "json",
          withCredentials: true,
          observe: "response",
        })
        .subscribe((response: any) => {
          // console.log(response);
          resolve(response.body);
        });
    });
  }
}
