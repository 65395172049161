import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ProchesService } from 'src/app/main/patient-area/mes-proches/proches.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentsService {

  private _url: string;
  appointment: any= [];
  onAppointment = new BehaviorSubject([]);
  constructor(private _httpClient: HttpClient,private prochesService : ProchesService) {
    this._url = environment.url_api;
    this.getRdv();
   }
  /**
* Récupère les rendez-vous d'un patient
*
* @returns Promise<any>
**/
  getRdv(): Promise<any> {
    return new Promise((resolve, reject) => {
        let httpParams = new HttpParams();
        this._httpClient
        .post(this._url + "/patientArea/appointments",httpParams, {
          responseType: "json",
          withCredentials: true,
          observe: "response",
        })
        .subscribe(
          (response: any) => {
            if (response.body.code === 1) {
              this.appointment = response.body.data;
              this.emitAppointment();
              resolve(response.body.data);
            } else {
              resolve([]);
            }
          }
        );
    });
  }

  /**
   * Emettre les rdv
   */
   emitAppointment(){
    this.onAppointment.next(this.appointment)
  }
}
