<div id="app" class="app" [dir]="(settings.rtl) ? 'rtl' : 'ltr'"
            [ngClass]="[settings.theme, 'toolbar-'+settings.toolbar]" 
            [class.main-toolbar-fixed]="settings.mainToolbarFixed"> 
    <router-outlet></router-outlet>
    <ng-progress 
        [spinner]="false"
        [meteor]="false" 
        color="#0F847E"></ng-progress>
</div>


