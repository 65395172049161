import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from "@angular/router";
import { BehaviorSubject, Observable } from 'rxjs';
import { UtilsService } from 'src/app/helpers/utils.service';

@Injectable({
    providedIn: 'root'
})
export class SecurityService {

    private _url: string;
    isConnected: boolean = false;
    isConnectedSubject = new BehaviorSubject<boolean>(false);
    connected$ : Observable<boolean> = this.isConnectedSubject.asObservable();
    
    userDataSubject = new BehaviorSubject<any>(null);
    user$ : Observable<any> = this.userDataSubject.asObservable();

    
    emitIsConnected(isConnected) {
    // console.log('emit is connect', isConnected)
        this.isConnectedSubject.next(isConnected);
    }
    emitDataUser(userData){
        // console.log('emit user data',userData)
        this.userDataSubject.next(userData);
    }

    user : any;
    connect: any;
    connectobjet: any;

    // ------------
    authState = new BehaviorSubject<any>(false)

    constructor(private _httpClient: HttpClient,private _utilsService : UtilsService) {
        this._url = environment.url_api;
        this.user = this._utilsService.getSession("userdata");
        if(!this.user){
            this.authenticated();
        } else {
            this.isConnected = true;
            this._utilsService.setSession('authenticated', this.isConnected);
            this.emitIsConnected(this.isConnected);
            this.emitDataUser(this.user);
        }


    }

    /**
       * Resolver
       *
       * @param {ActivatedRouteSnapshot} route
       * @param {RouterStateSnapshot} state
       * @returns {Observable<any> | Promise<any> | any}
    */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([
               // this.checkAuthenticated()
            ]).then(([]) => {
                resolve([]);
            }, reject);
        });
    }
    /**
   * sign-in
   *
   * @returns Promise<any>
   **/
    authentication(login, password): Promise<any> {
        var httpParams = new HttpParams();
        httpParams = httpParams.append('login', login);
        httpParams = httpParams.append('password', password);

        return new Promise((resolve, reject) => {
            this._httpClient.post(this._url + '/security/sign_in', httpParams, {
                responseType: 'json',
                withCredentials: true,
                observe: 'response'
            }).subscribe((response: any) => {
                resolve(response.body);
        
            });
        })
    }
    /**
    * user connected
    *
    * @returns Promise<any>
    **/
    authenticated(): Promise<any> {
        var httpParams = new HttpParams();
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._url + "/security/authenticated", httpParams, {
                    responseType: "json",
                    withCredentials: true,
                    observe: "response",
                }).subscribe((response: any) => {
                        if (response.body.code === 1) {
                            this.isConnected = response.body.data.connected;
                            this._utilsService.setSession('authenticated', this.isConnected);
                            this.emitIsConnected(this.isConnected);
                            this.getDataUser().then(userData => {
                                this._utilsService.setSession('userdata', userData);
                                this.emitDataUser(userData)
                            });
                            resolve(response.body.data);
                        }
                        else {
                            resolve([]);
                        }

                    }
                );
        });
    }
    /**
     * logout
     *
     * @returns Promise<any>
    **/
    logout(): Promise<any> {
        return new Promise((resolve) => {
            let httpParams = new HttpParams();
            this._httpClient
                .post(this._url + "/security/logout", httpParams, {
                    responseType: "json",
                    withCredentials: true,
                    observe: "response",
                })
                .subscribe(
                    (response: any) => {
                        this.isConnected = response.body.data.connected;
                        this.emitIsConnected(this.isConnected);
                        this._utilsService.removeSession("userdata");
                        resolve(response);
                    }
                );
        });
    }

    /**
    * Get data user
    *
    * @returns Promise<any>
    **/
    getDataUser(): Promise<any> {
        return new Promise((resolve) => {
            let httpParams = new HttpParams();
            this._httpClient
                .post(this._url + "/security/data_user",httpParams, {
                    responseType: "json",
                    withCredentials: true,
                    observe: "response",
                })
                .subscribe(
                    (response: any) => {
                        if (response.body.code === 1) {
                            this.user = response.body.data;
                            resolve(response.body.data);
                        } else {
                            resolve([]);
                        }
                    }
                );
        });
    }
    /**
    * sign-up
    *
    * @returns Promise<any>
    **/
    sign_up(data: any): Promise<any> {
        /**
         * Params
         */
        let httpParams = new HttpParams();

        // httpParams = httpParams.append('Param_test', data.param_test);
        httpParams = httpParams.append('Param_nom', data.lastname);
        httpParams = httpParams.append('Param_prenom', data.firstname);
        httpParams = httpParams.append('Param_phone', data.mobile);
        // httpParams = httpParams.append('Param_iso2', data.CountryISO);
        httpParams = httpParams.append('Param_email', data.email);
        httpParams = httpParams.append('Param_password', data.password);


        return new Promise((resolve, reject) => {
            this._httpClient.post(this._url + '/public/InitInscription', httpParams,
                {
                    // headers: headers,
                    // params: httpParams,
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response'
                }).subscribe((response: any) => {
                    if (response.body.code === 1) {
                        resolve(response.body.data);
                    }
                    else
                        resolve([]);

                },
                    err => {
                        console.log('Error: ' + err.error);
                        console.log('Name: ' + err.name);
                        console.log('Message: ' + err.message);
                        console.log('Status: ' + err.status);
                    }, reject);

        });

    }
    /**
    * Reset Password
    *
    * @returns Promise<any>
    **/
    resetPassword(data): Promise<any> {

        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

        /**
         * Params
         */
        let httpParams = new HttpParams();
        httpParams = httpParams.append('Param_contact', data.contact);
        httpParams = httpParams.append('Param_contactType', data.contactType); // Data.contactType


        return new Promise((resolve, reject) => {

            this._httpClient.post(this._url + '/security/reset_password', httpParams,
                {
                    headers: headers,
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response'
                }).subscribe((response: any) => {
                    resolve(response);
                },
                    err => {
                        console.log('Error: ' + err.error);
                        console.log('Name: ' + err.name);
                        console.log('Message: ' + err.message);
                        console.log('Status: ' + err.status);
                    }, reject);
        });
    }
    /**
    * Validated Code
    *
    * @returns Promise<any>
    **/
    validateCode(data): Promise<any> {
        /**
         * Params
         */
        let httpParams = new HttpParams();
        httpParams = httpParams.append('Param_Code', data.codeValidation);

        return new Promise((resolve, reject) => {


            this._httpClient.post(this._url + '/security/validate_code', httpParams,
                {
                    // headers: headers,
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response'
                }).subscribe((response: any) => {
                    if (response.body.code === 1) {
                        resolve(response.body.data);
                    }
                    else
                        resolve([]);

                },
                    err => {
                        console.log('Error: ' + err.error);
                        console.log('Name: ' + err.name);
                        console.log('Message: ' + err.message);
                        console.log('Status: ' + err.status);
                    }, reject);
        });

    }
    /**
    *  Change Password
    *
    * @returns Promise<any>
    **/
    changePassword(data): Promise<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');


        /**
         * Params
         */
        let httpParams = new HttpParams();
        httpParams = httpParams.append('Param_NewPassword', data.password);

        return new Promise((resolve, reject) => {


            this._httpClient.post(this._url + '/security/updatePassword', httpParams,
                {
                    headers: headers,
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response'
                }).subscribe((response: any) => {
                    resolve(response);
                },
                    err => {
                        console.log('Error: ' + err.error);
                        console.log('Name: ' + err.name);
                        console.log('Message: ' + err.message);
                        console.log('Status: ' + err.status);
                    }, reject);
        });

    }
    initInscription(data: any): Promise<any> {
        let headers = new HttpHeaders();
        let httpParams = new HttpParams();
        httpParams = httpParams.append('Param_Genre:', data.genre);
        httpParams = httpParams.append('Param_nom', data.lastname);
        httpParams = httpParams.append('Param_prenom', data.firstname);
        httpParams = httpParams.append('Param_phone', data.mobile);
        httpParams = httpParams.append('Param_GSM', data.mobile);//todo change this
        httpParams = httpParams.append('Param_Pays', data.mobile);//todo change this
        httpParams = httpParams.append('Param_iso2', data.mobile);//todo change this
        httpParams = httpParams.append('Param_Prefixe', data.prefix);
        httpParams = httpParams.append('Param_email', data.email);
        httpParams = httpParams.append('Param_password', data.password);
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._url + '/public/InitInscription', httpParams,
                {
                    headers: headers,
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response'
                }).subscribe((response: any) => {
                    resolve(response);
                },
                err => {
                    console.log('Error: ' + err.error);
                    console.log('Name: ' + err.name);
                    console.log('Message: ' + err.message);
                    console.log('Status: ' + err.status);
                }, reject);
        });
    }

    /**
     * Retreive Person Data
     * * @param validationcode
     * *@param data: Array
     * * @returns {Promise<any>}
     */
    validateInscription(data: any): Promise<any> {
        /**
         * Header
         */
        let headers = new HttpHeaders();
        /**
         * Params
         */
        let httpParams = new HttpParams();
        httpParams = httpParams.append('Param_CodeSecurite:', data.code);
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._url + '/public/ValidateInscription', httpParams,
                {
                    headers: headers,
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response'
                }).subscribe((response: any) => {
                    resolve(response);
                },
                    err => {
                        console.log('Error: ' + err.error);
                        console.log('Name: ' + err.name);
                        console.log('Message: ' + err.message);
                        console.log('Status: ' + err.status);
                    }, reject);
        });
    }
    inscriptionClientPassenger(data: any): Promise<any> {
        let headers = new HttpHeaders();
        let httpParams = new HttpParams();
        httpParams = httpParams.append('Param_Genre:', data.genre);
        httpParams = httpParams.append('Param_nom', data.lastname);
        httpParams = httpParams.append('Param_prenom', data.firstname);
        httpParams = httpParams.append('Param_phone', data.mobile);
        httpParams = httpParams.append('Param_Prefixe', data.prefix);
        httpParams = httpParams.append('Param_email', data.email);
        httpParams = httpParams.append('Param_password', data.password);
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._url + '/public/InscriptionClientPassager', httpParams,
                {
                    headers: headers,
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response'
                }).subscribe((response: any) => {
                    resolve(response);
                },
                err => {
                    console.log('Error: ' + err.error);
                    console.log('Name: ' + err.name);
                    console.log('Message: ' + err.message);
                    console.log('Status: ' + err.status);
                }, reject);
        });
    }

    validateInscriptionPassenger(data: any): Promise<any> {
        let headers = new HttpHeaders();
        let httpParams = new HttpParams();
        httpParams.append('Param_CodeSecurite:', data.code);
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._url + '/public/ValidateInscriptionPassager', httpParams,
                {
                    headers: headers,
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response'
                }).subscribe((response: any) => {
                    resolve(response);
                },
                err => {
                    console.log('Error: ' + err.error);
                    console.log('Name: ' + err.name);
                    console.log('Message: ' + err.message);
                    console.log('Status: ' + err.status);
                }, reject);
        });
    }
    /**
    * Retreive Person Data
    * 
    * * @returns {Promise<any>}
    */
    socialAuthentication(data: any): Promise<any> {

        /**
         * Header
         */
        let headers = new HttpHeaders();
        /**
         * Params
         */
        let httpParams = new HttpParams();
        httpParams = httpParams.append('email', data.email);
        httpParams = httpParams.append('token', data.id);
        httpParams = httpParams.append('provider', data.provider);
        httpParams = httpParams.append('service', data.provider);// à supprimer après avoir corriger l'action API
        if(data.provider === 'GOOGLE') {
            httpParams = httpParams.append('first_name', data.firstName); // first_name
            httpParams = httpParams.append('last_name', data.lastName); // last_name
        } else {
            httpParams = httpParams.append('first_name', data.first_name); // first_name
            httpParams = httpParams.append('last_name', data.last_name); // last_name
        }
        httpParams = httpParams.append('username', data.name);
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._url + '/account/auth_social_media', httpParams,
                {
                    headers: headers,
                    // params: httpParams,
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response'
                }).subscribe((response: any) => {
                    if (response.body.code === 1) {
                        resolve(response.body.data);
                    }
                    else resolve([]);

                },
                    err => {
                        resolve([]);
                        console.log('Error: ' + err.error);
                        console.log('Name: ' + err.name);
                        console.log('Message: ' + err.message);
                        console.log('Status: ' + err.status);
                    }, reject);
        });
    }

    /**
     * checkAuthenticated
     *
     * @returns Promise<any>
    **/
   /*  checkAuthenticated(): Promise<any>{
        return new Promise((resolve, reject) => {
            let dateNow = new Date().getTime();
            this.connectobjet = this._utilsService.getSession('authenticated');
            if(this.connectobjet){
                if (this.connectobjet.connected) { //|| this.connectobjet.expire_date <= dateNow
                    if(!this._utilsService.getSession('getDataUser')){
                        this.getDataUser().then((res) => {
                            console.log('res', res);
                        });
                    }

                }
            }
         
            resolve('OK');
        });
    } */
}
