import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProchesService {

  private api: string = environment.url_api;
  proches = [];
  proches$ = new BehaviorSubject([]);

  constructor(private http : HttpClient) {
    this.getProches();
   }

  /**
   * Récupère les proches d'un patient
   */
  getProches(){
    let httpParams = new HttpParams();
    this.http.post(this.api+"/patientArea/relatives",httpParams).subscribe(
      (res : any)=>{
        this.proches = res.data;
        this.emitProches();
        // console.log(res);
        
      },
      (error)=>{
        console.log(error)
      })
  }

  /**
   * Emettre les proches
   */
  emitProches(){
    this.proches$.next(this.proches)
  }
}
