import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';

/**
 * @description
 * @class
 */
@Injectable()

@Injectable({
  providedIn: 'root'
})
export class DetailPracticeService {


  private _url: string;
  dataDetailSubject: Subject<any> = new Subject<any>();

  /**
   * Constructor
   *
   * @param { HttpClient } _httpClient
   */
  constructor(private _httpClient: HttpClient) {

    this._url = environment.url_api;

  }


  /**
 * getAnnuaireSante
 *
 * return {Promise<any>}
 */
    
   getDirectory(Data): Promise<any> {

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

    /**
     * Params
     */
    let httpParams = new HttpParams();
    httpParams = httpParams.append('url', Data.url_public);
    if(Data.reference) httpParams = httpParams.append('reference', Data.reference);
    // Data.contactType

    return new Promise((resolve, reject) => {

        this._httpClient.post(this._url + '/planning/get_directory', httpParams,
            {
                headers: headers,
                responseType: 'json',
                withCredentials: true,
                observe: 'response'
            }).subscribe((response: any) => {
                let res = response.body;
                if(res.code === 1){
                    resolve(res.data);
                }else{
                    resolve([]);
                }
            },
            err => {
                console.log('Error: ' + err.error);
                console.log('Name: ' + err.name);
                console.log('Message: ' + err.message);
                console.log('Status: ' + err.status);
            }, reject);
    });
}
}
