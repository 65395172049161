<a href="https://www.instagram.com/tictacsante/" target="blank" class="social-icon">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="icon_i_136_instagram" width="16" height="16">
    <path fill="currentColor"
          d="M6.05 2.68h19.93c1.9 0 3.45 1.55 3.45 3.44v19.93c0 1.9-1.55 3.44-3.45 3.44H6.05a3.45 3.45 0 01-3.44-3.44V6.12c0-1.9 1.55-3.44 3.44-3.44zm16.1 2.98c-.67 0-1.21.54-1.21 1.2v2.9c0 .66.54 1.2 1.2 1.2h3.04c.66 0 1.2-.54 1.2-1.2v-2.9c0-.66-.54-1.2-1.2-1.2h-3.03zm4.25 8.36h-2.36c.22.72.34 1.5.34 2.3 0 4.46-3.73 8.07-8.33 8.07S7.7 20.78 7.7 16.32c0-.8.12-1.58.34-2.3H5.6v11.32c0 .59.48 1.07 1.07 1.07h18.67c.59 0 1.07-.48 1.07-1.07V14.02zM16.05 10.8a5.3 5.3 0 00-5.39 5.21c0 2.89 2.41 5.22 5.39 5.22s5.38-2.33 5.38-5.22a5.3 5.3 0 00-5.38-5.21z"></path>
  </svg>
</a>
<a href="https://www.facebook.com/tictacsante" target="blank" class="social-icon">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="icon_i_065_facebook" width="16" height="16">
    <path fill="currentColor" d="M19.84 6h3.72V0h-3.4c-6.13 0-9.07 2.8-9.07 7v3.67H8.45v6.22h2.64V32h7.18V16.89h4.53l.76-6.22h-5.3V7.5c0-.75.56-1.5 1.58-1.5z"></path>
  </svg>
</a>
<a href="https://twitter.com/TicTacSante" target="blank" class="social-icon">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="icon_i_002_twitter" width="16" height="16">
    <path fill="currentColor" d="M32 6.08c-1.18.52-2.44.87-3.77 1.03a6.58 6.58 0 002.89-3.63c-1.27.75-2.68 1.3-4.17 1.6a6.56 6.56 0 00-11.2 5.98A18.64 18.64 0 012.24 4.2a6.54 6.54 0 002.03 8.76 6.55 6.55 0 01-2.98-.82v.09a6.57 6.57 0 005.27 6.43 6.58 6.58 0 01-2.97.12 6.57 6.57 0 006.14 4.56A13.17 13.17 0 010 26.05 18.59 18.59 0 0010.06 29c12.08 0 18.68-10 18.68-18.67 0-.29 0-.57-.02-.85A13.4 13.4 0 0032 6.08z"></path>
  </svg>
</a>
<a *ngIf="!environment.production" href="https://www.youtube.com/channel/UCCUW5IGOw1z0gfAWWWPLjJw" target="blank" class="social-icon">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="icon_i_001_youtube" width="16" height="16">
    <path fill="currentColor" d="M31.68 9.6s-.32-2.19-1.28-3.15c-1.21-1.27-2.58-1.22-3.2-1.3C22.72 4.84 16 4.9 16 4.9s-6.72-.06-11.2.27c-.62.07-1.98.05-3.2 1.31C.64 7.44.32 9.62.32 9.62S0 12.18 0 14.76v2.41c0 2.58.32 5.16.32 5.16s.32 2.19 1.28 3.16c1.21 1.27 2.81 1.22 3.53 1.36 2.55.24 10.87.32 10.87.32s6.72-.01 11.2-.34c.62-.07 1.99-.08 3.2-1.34.96-.97 1.28-3.16 1.28-3.16s.32-2.58.32-5.16v-2.41c0-2.58-.32-5.15-.32-5.15zm-20.6 11.5V10.43l10.66 5.36-10.66 5.3z"></path>
  </svg>
</a>
<a href="https://www.linkedin.com/company/tictacsante/" target="blank" class="social-icon">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="icon_i_138_linkedin" width="16" height="16">
    <path fill="currentColor" d="M25.62 4.33H5.16c-.97 0-1.77.77-1.77 1.72V26.6c0 .95.8 1.73 1.77 1.73h20.46c.98 0 1.78-.77 1.78-1.73V6.05c0-.95-.8-1.72-1.78-1.72zM10.5 24.79H6.95V13.33h3.56V24.8zM8.73 11.76a2.06 2.06 0 112.06-2.07c0 1.15-.92 2.07-2.06 2.07zM23.85 24.8h-3.56v-5.57c0-1.33-.03-3.04-1.85-3.04-1.86 0-2.14 1.45-2.14 2.94v5.67h-3.56V13.34h3.42v1.56h.04a3.74 3.74 0 013.37-1.85c3.6 0 4.27 2.38 4.27 5.46v6.28z"></path>
  </svg>
</a>



