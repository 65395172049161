import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable} from 'rxjs';
import { environment } from 'src/environments/environment';




@Injectable({
    providedIn: 'root'
})
export class AccountService implements Resolve<any>
{
    timeline: any;
    AccountInfo: any;
    photosVideos: any;
    account: any;
    dataUser : any;
   

    timelineOnChanged: BehaviorSubject<any>;
    aboutOnChanged: BehaviorSubject<any>;
    photosVideosOnChanged: BehaviorSubject<any>;
    accountOnChanged: BehaviorSubject<any>;
    adressOnChanged:BehaviorSubject<any>;
    OnAccountChanged:BehaviorSubject<any>;
    // Private
   private _url: string;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        

    )
    {
        // Set the defaults
        this.timelineOnChanged = new BehaviorSubject({});
        this.aboutOnChanged = new BehaviorSubject({});
        this.photosVideosOnChanged = new BehaviorSubject({});
        this.accountOnChanged = new BehaviorSubject({});
        this.adressOnChanged = new BehaviorSubject({});
        this.OnAccountChanged = new BehaviorSubject({}); 

        this._url = environment.url_api;
       
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getAbout(),
                this.getDataUser({}),

            ]).then(
                ([AccountInfo, dataUser]) => {
                    this.AccountInfo = AccountInfo;
                    this.dataUser = dataUser;
                    resolve(true);
                },
                reject
            );
        });
    }

    /**
     * Get update infos profile
     */
     updateInfosAccount(data){
        var httpParams = new HttpParams();
        httpParams = httpParams.append('Param_Oper', 'adress');
        httpParams = httpParams.append('Param_Adresse', data.adresse);
        httpParams = httpParams.append('Complement_Adresse', data.complementAdresse);
        httpParams = httpParams.append('Code_Postale', data.codePostale);
        httpParams = httpParams.append('Param_Pays', data.pays );
        httpParams = httpParams.append('Param_Ville', data.ville);

        return new Promise((resolve,reject)=>{
            this._httpClient.post(this._url + '/patient/UpdateInfosAccount',httpParams,{
                responseType: 'json',
                withCredentials: true,
                observe: 'response'
            }).subscribe((response : any)=>{
                console.log(response.body);
            });
        })
    }

    /**
     * Get authentication
     */
     authentication() : Promise<any[]>{
        var httpParams = new HttpParams();
        httpParams = httpParams.append('Param_Login', 'demo1');
        httpParams = httpParams.append('Param_Password', '12369');

        return new Promise((resolve,reject)=>{
            this._httpClient.post(this._url + '/security/authentification',httpParams,{
                responseType: 'json',
                withCredentials: true,
                observe: 'response'
            }).subscribe((response : any)=>{
                console.log(response.body);
            });
        })
    }
    

    /**
     * Get infos Profile
     */
     getInfosAccount(): Promise<any[]>
     {
         return new Promise((resolve,reject)=>{
             this._httpClient.get(this._url + '/patients/findPatientByUserId',{
                 responseType: 'json',
                 withCredentials: true,
                 observe: 'response'
             })
             .subscribe((response: any) => {
                    //  console.log("getInfoAccount ==> ",response);
                     if(response.body.code ===  1){
                        resolve(response.body.data);
                     }
                     else
                        resolve([]);

                    
                 });
         })
     }

    /**
     * Get account informations
     */
    getAbout(): Promise<any[]>
    {
        return new Promise((resolve, reject) => {

            this._httpClient.get('api/profile-about')
                .subscribe((reponse: any) => {
                    this.AccountInfo = reponse;
                    this.aboutOnChanged.next(this.AccountInfo);
                    resolve(this.AccountInfo);
                }, reject);
        });
    }

   
   /**
     * Update user data
     *
     * @param accountData
     * @returns {Promise<any>}
     */
    updateAbout(accountData): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post('api/profile-about/' + this.AccountInfo.id, {...accountData})
                .subscribe(response => {
                    this.getAbout();
                    resolve(response);
                });
        });
    }

    /************************ */
    /**
     * update account data 
     *
     * @param Data: Array
     * @returns {Promise<any>}
     */
    updateAccountData(Data): Promise<any> {

        var httpParams = new HttpParams();
        httpParams = httpParams.append('Param_Civilite', Data.civilite);
        httpParams = httpParams.append('Param_Nom', Data.nom);
        httpParams = httpParams.append('Param_Prenom', Data.prenom);
        httpParams = httpParams.append('Param_Datenaissance', Data.DateDeNaissance);
        httpParams = httpParams.append('Param_Lieunaissance', Data.LieuDeNaissance);
        httpParams = httpParams.append('Param_Mobile', Data.GSM);
        httpParams = httpParams.append('Param_Telephone', Data.Telephone);
        httpParams = httpParams.append('Param_Ville', Data.ville);

        return new Promise((resolve, reject) => {


            this._httpClient.post(this._url + '/account/change_account', httpParams,
                {
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response'
                }).subscribe((response: any) => {
                    console.log(response);
                    resolve(response);
                },
                err => {
                    console.log('Error: ' + err.error);
                    console.log('Name: ' + err.name);
                    console.log('Message: ' + err.message);
                    console.log('Status: ' + err.status);
                }, reject);
        });
    }
    updateAdress(DataAdress): Promise<any> {
    
        /**
         * Params
         */
        var httpParams = new HttpParams();
        httpParams = httpParams.append('Param_Adresse', DataAdress.adresse);
        httpParams = httpParams.append('Param_CodePostal', DataAdress.codePostale) ;
        httpParams = httpParams.append('Param_ComplementAdresse', DataAdress.complementAdresse);
        httpParams = httpParams.append('Param_Pays', DataAdress.pays);
        httpParams = httpParams.append('Param_Ville', DataAdress.ville);
       
        return new Promise((resolve, reject) => {


            this._httpClient.post(this._url + '/patients/change_address', httpParams,
                {
                   // headers: headers,
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response'
                }).subscribe((response: any) => {
                    // console.log(response);
                    resolve(response);
                },
                err => {
                    console.log('Error: ' + err.error);
                    console.log('Name: ' + err.name);
                    console.log('Message: ' + err.message);
                    console.log('Status: ' + err.status);
                }, reject);
        });
    }

    /**
     * update account security
     *
     * @param DataSecurity: Array
     * @returns {Promise<any>}
     */
    updateAccountSecurity(DataSecurity): Promise<any> {
       
        /**
         * Params
         */
        var httpParams = new HttpParams();
        httpParams = httpParams.append('Param_AncienPassword', DataSecurity.oldPassword);
        httpParams = httpParams.append('Param_NouveauPassword', DataSecurity.newPassword);
        httpParams = httpParams.append('Param_Notification', DataSecurity.notification);
       
        return new Promise((resolve, reject) => {


            this._httpClient.post(this._url + '/security/update_password', httpParams,
                {
                    //headers: headers,
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response'
                }).subscribe((response: any) => {
                    // console.log(response);
                    resolve(response.body);
                },
                err => {
                    console.log('Error: ' + err.error);
                    console.log('Name: ' + err.name);
                    console.log('Message: ' + err.message);
                    console.log('Status: ' + err.status);
                }, reject);
        });
    }
    /**
     * get Data user 
     *
     * @param DataUser: Array
     * @returns {Promise<any>}
     */
    getDataUser(DataUser): Promise<any> {
       
         /**
         * Params
         */
        var httpParams = new HttpParams();
        //httpParams = httpParams.append('Param_ID', '273');
        
        return new Promise((resolve, reject) => {

            this._httpClient.post(this._url + '/account/getInfo_account', httpParams,
                {
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response'
                }).subscribe((response: any) => {
                   if(response.body){
                    this.dataUser = response.body.data;
                    this.OnAccountChanged.next(this.dataUser); // ToDo
                    //this._serviceSecurityHelper.checkAuthentification(response.body); 

                   }
                    resolve(response);
                },
                err => {
                    console.log('Error: ' + err.error);
                    console.log('Name: ' + err.name);
                    console.log('Message: ' + err.message);
                    console.log('Status: ' + err.status);
                }, reject);
        });
    }
    /**
     * get Data user 
     *
     * @param Data: Array
     * @returns {Promise<any>}
     */
    disableAccount(): Promise<any> {
    
         /**
         * Params
         */
        var httpParams = new HttpParams();
       
        return new Promise((resolve, reject) => {


            this._httpClient.post(this._url + '/account/disable_account', httpParams,
                {
                   // headers: headers,
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response'
                }).subscribe((response: any) => {
                    resolve(response.body);
                },
                err => {
                    console.log('Error: ' + err.error);
                    console.log('Name: ' + err.name);
                    console.log('Message: ' + err.message);
                    console.log('Status: ' + err.status);
                }, reject);
        });
    }
     /**
     * get Data user 
     *
     * @param Data: Array
     * @returns {Promise<any>}
     */
    VerifyEmail(Data): Promise<any> {
         /**
         * Params
         */
        var httpParams = new HttpParams();
        httpParams = httpParams.append('Param_email', Data.email);
        

        return new Promise((resolve, reject) => {


            this._httpClient.post(this._url + '/account/verify_email', httpParams,
                {
                   // headers: headers,
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response'
                }).subscribe((response: any) => {
                    console.log(response);
                    if(response.body.code === 1){
                        resolve(response.body.data);
                    }
                    else 
                        resolve([]);

                
                },
                err => {
                    console.log('Error: ' + err.error);
                    console.log('Name: ' + err.name);
                    console.log('Message: ' + err.message);
                    console.log('Status: ' + err.status);
                }, reject);
        });
    }
    /**
     * get Data user 
     *
     * @param Data: Array
     * @returns {Promise<any>}
     */
    VerifyGSM(Data): Promise<any> {
       

         /**
         * Params
         */
        var httpParams = new HttpParams();
        httpParams = httpParams.append('Param_GSM', Data.GSM);
        
        return new Promise((resolve, reject) => {

            this._httpClient.post(this._url + '/account/verify_phone', httpParams,
                {
                    //headers: headers,
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response'
                }).subscribe((response: any) => {
                    console.log(response);
                    if(response.body.code === 1){
                        resolve(response.body.data);
                    }
                    else
                        resolve([]);
                },
                err => {
                    console.log('Error: ' + err.error);
                    console.log('Name: ' + err.name);
                    console.log('Message: ' + err.message);
                    console.log('Status: ' + err.status);
                }, reject);
        });
    }
    validateEmail(Data): Promise<any> {
       
         /**
         * Params
         */
        var httpParams = new HttpParams();
        httpParams = httpParams.append('Param_Code', Data.emailCode);
        
        return new Promise((resolve, reject) => {

            this._httpClient.post(this._url + '/account/validate_email', httpParams,
                {
                   // headers: headers,
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response'
                }).subscribe((response: any) => {
                    console.log(response);
                    resolve(response);
                },
                err => {
                    console.log('Error: ' + err.error);
                    console.log('Name: ' + err.name);
                    console.log('Message: ' + err.message);
                    console.log('Status: ' + err.status);
                }, reject);
        });
    }
    validateGSM(Data): Promise<any> {
       
         /**
         * Params
         */
        var httpParams = new HttpParams();
        httpParams = httpParams.append('Param_Code', Data.phoneCode);
        
        return new Promise((resolve, reject) => {

            this._httpClient.post(this._url + '/account/validate_gsm', httpParams,
                {
                    //sheaders: headers,
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response'
                }).subscribe((response: any) => {
                    console.log(response);
                    resolve(response);
                },
                err => {
                    console.log('Error: ' + err.error);
                    console.log('Name: ' + err.name);
                    console.log('Message: ' + err.message);
                    console.log('Status: ' + err.status);
                }, reject);
        });
    }
        
        
}
