import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { environment } from 'src/environments/environment';


@Injectable()
export class PlanningService implements Resolve<any> {
    times_slote: any;
    motifs: any;
    onSelectedTime: BehaviorSubject<any>;
    // Private
    private _url: string;
    rdv: { practicien: any; time: any; timeSlot: any; };
    rdvSubject : BehaviorSubject<any | null> = new BehaviorSubject(null);

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient) {
        this.onSelectedTime = new BehaviorSubject([]);
        this.rdvSubject = new BehaviorSubject([]);
        this._url = environment.url_api;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([
                
            ]).then(([]) => {
                resolve([]);
            }, reject);
        });
    }

    /**
     * Add Call
     *
     * @param data
     * @returns {Promise<any>}
     */
    getTimesSlote(data: any): Promise<any> {
        /**
         * Params
         */
        let httpParams = new HttpParams();
        httpParams = httpParams.append('reference_directory', data.reference);
        httpParams = httpParams.append('reference', data.reference);
        httpParams = httpParams.append('displays', data.display_days);
        httpParams = httpParams.append('date_init', data.date_init);
       if(data.refType) httpParams = httpParams.append('type', data.refType);
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(this._url + '/planning/time_slots', httpParams, {
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response',
                })
                .subscribe(
                    (response: any) => {
                        resolve(response);
                    }
                );
        });
    }

    /**
     * Add Call
     *
     * @param data
     * @returns {Promise<any>}
     */
    startProcessRdv(data: any): Promise<any> {
        /**
         * Params
         */
        let httpParams = new HttpParams();
        // console.log(data)
        httpParams = httpParams.append('idPraticien', data.idPraticien);
        httpParams = httpParams.append('reference', data.reference);
        httpParams = httpParams.append('start_hour', data.hour);
        httpParams = httpParams.append('Default', data.is_default);
        httpParams = httpParams.append('type', data.type_ref);
        httpParams = httpParams.append('date', data.date_init);

        return new Promise((resolve, reject) => {
            this._httpClient
                .post(this._url + '/booking/start_process', httpParams, {
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response',
                })
                .subscribe(
                    (response: any) => {
                        // console.log(response);
                        if(response.body.code === 1){
                            resolve(response.body.data)
                        }
                        else{
                            this.selectedTime({
                                is_connect: data.isconnect,
                            });
                            resolve([]);
                        }
                    },
                    (err) => {
                        console.log('Error: ' + err.error);
                        console.log('Name: ' + err.name);
                        console.log('Message: ' + err.message);
                        console.log('Status: ' + err.status);
                    },
                    reject
                );
        });
    }
    getMotifs(data: any): Promise<any> {
        /**
         * Params
         */
        let httpParams = new HttpParams();
        if(data.url_public) httpParams = httpParams.append('Param_url', data.url_public);
        if(data.reference) httpParams = httpParams.append('reference_practice', data.reference);

        return new Promise((resolve, reject) => {
            this._httpClient
                .post(this._url + '/planning/reasons', httpParams, {
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response',
                })
                .subscribe(
                    (response: any) => {
                        resolve(response);
                    },
                    (err) => {
                        console.log('Error: ' + err.error);
                        console.log('Name: ' + err.name);
                        console.log('Message: ' + err.message);
                        console.log('Status: ' + err.status);
                    },
                    reject
                );
        });
    }

    selectedTime(data: any) {
        this.onSelectedTime.next(data);
    }

    updateRdv(practicien,time,timeSlot){
        this.rdv = {
            practicien : practicien,
            time : time,
            timeSlot : timeSlot,
        }
        this.emitRdv();
      }
    
    emitRdv() {
        this.rdvSubject.next(this.rdv);
    }
}
