import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { Practice } from 'src/app/models/practice.model';

import { environment } from 'src/environments/environment';
/**
 * @description
 * @class
 */
@Injectable()
export class IndexService implements Resolve<any>{

    public new_practices: Practice[];
    private url_api: string;
     Data: any   = {};
     net_infos : any;
     custom_network : any;
     network;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(  private _httpClient: HttpClient
    ) {

        this.network =  localStorage.getItem('network_data');
        this.custom_network = localStorage.getItem("chosenLocation");
        this.net_infos = JSON.parse(this.custom_network ? this.custom_network : this.network);
        this.Data.country      = this.net_infos.country.toLowerCase();

        this.url_api = environment.url_api;

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                
           
                this.getNewPractices(this.Data)

            ]).then(
                ([new_practices]) => {

                    this.new_practices = new_practices;

                    resolve([]);
                },
                reject
            );
        });
    }

    /**
     * Get New Practices
     *
     * @returns {Promise<any>}
     */
    getNewPractices(data): Promise<any> {
        /**
        * Params
        */
        let httpParams = new HttpParams();
        httpParams = httpParams.append('country', data.country);
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.url_api + '/search/new_practices', httpParams)
                .subscribe((response: any) => {
                    if (response.code === 1) {
                        resolve(response.data);
                        this.new_practices = response.data
                    }

                    else {
                        resolve([]);
                    }


                },
                    (err) => {
                        console.log('Error: ' + err.error);
                        console.log('Name: ' + err.name);
                        console.log('Message: ' + err.message);
                        console.log('Status: ' + err.status);
                    }, reject);
        }
        );
    }

}
